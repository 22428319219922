import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png"
const Header = () => {
    const [display, setDisplay] = useState(false);
    const toggleChecked = () => setDisplay(value => !value);
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname === path;
    };

    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 429)
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div id="header" className="section header-section header-section-04">
                <div className="container">
                    <div className="header-wrap">
                        <div className="header-logo">
                            <Link to="/"><img src="../../assets/images/logo.png" alt="logo.png" title="Bilvaleaf" /></Link>
                        </div>
                        <div className="header-menu d-none d-lg-block">
                            <ul className="main-menu">
                                <li><NavLink exact to="/" activeClassName="active" isActive={() => isActive("/")} title="Home">Home</NavLink></li>
                                <li><NavLink to="/about" activeClassName="active" isActive={() => isActive("/about")} title="About Us">About us</NavLink></li>
                                <li>
                                    <NavLink to="/service" activeClassName="active" isActive={() => isActive("/service")} title="Services">Services</NavLink>
                                </li>
                                <li><NavLink to="/blog" activeClassName="active" isActive={() => isActive("/blog")} title="Blogs">Blogs</NavLink></li>
                                <li><NavLink to="/contact" activeClassName="active" isActive={() => isActive("/contact")} title="Contact">Contact </NavLink></li>
                            </ul>
                        </div>

                        <div className="header-meta">
                            {
                                isWideScreen ?

                                    <div data-aos={isWideScreen ? "fade-right" : "undefined"} className="footer-social aos-init aos-animate"><ul className="social"><li><a className="facbook" href="https://www.facebook.com/profile.php?id=61560439168465" target="_blank" title="FACEBOOK"><i className="fab fa-facebook-f"></i></a></li><li><a className="instagram" href="https://www.instagram.com/bilvaleaf/" target="_blank" title="INSTAGRAM"><i className="fab fa-instagram"></i></a></li><li><a className="twitter" href="https://twitter.com/bilvaleaf" target="_blank" title="TWITTER">X</a></li>
                                        <li><a className="innkedin" href="https://www.linkedin.com/company/bilvaleaf-private-limited/" target="_blank" title="LINKEDIN"><i className="fab fa-linkedin-in"></i></a></li>
                                        <li>
                                            <a className="innkedin"
                                                href="https://youtube.com/@bilvaleafprivatelimited?si=udxBlecKE6VLzeLV"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title="YOUTUBE"
                                                style={{
                                                    color: '#FF0000',
                                                    textDecoration: 'none',
                                                    fontSize: '19px',
                                                }}
                                            >
                                                <i className="fab fa-youtube" style={{ fontSize: '24px' }}></i>
                                            </a>
                                        </li>
                                    </ul></div>
                                    : ''
                            }
                            <div className="header-toggle d-lg-none">
                                <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`offcanvas offcanvas-start`} id="offcanvasExample">
                <div className="offcanvas-header">
                    <div className="offcanvas-logo" data-bs-dismiss="offcanvas">
                        <NavLink to="/"><img src={logo} alt="logo.png" /></NavLink>
                    </div>
                    <button type="button" className="close-btn" data-bs-dismiss="offcanvas"><i className="flaticon-close"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="offcanvas-menu">
                        <ul className="main-menu">
                            <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas">
                                <NavLink exact activeClassName="active" isActive={() => isActive("/")} to="/">Home</NavLink>
                            </li>
                            <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas">
                                <NavLink activeClassName="active" isActive={() => isActive("/about")} to="/about">About us</NavLink>
                            </li>
                            <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas"><NavLink activeClassName="active" isActive={() => isActive("/")} to="/service">Services</NavLink>
                            </li>
                            {/* <li className={`active-menu ${display ? 'active' : ''} `}>
                                <Link to="#">Pages</Link>
                                <span className="menu-icon"></span>
                                <span className="menu-expand" onClick={(e) => { e.stopPropagation(); toggleChecked(); }}></span>
                                <ul className="sub-menu" style={{ boxSizing: "border-box", display: display ? 'block' : 'none' }}>
                                    <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas"><NavLink activeClassName="active" isActive={() => isActive("/")} to="/service">Services</NavLink></li>
                                </ul>
                            </li> */}

                            <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas"><NavLink activeClassName="active" isActive={() => isActive("/")} to="/blog">Blogs</NavLink> </li>
                            <li className={`active-menu ${display ? 'active' : ''} `} data-bs-dismiss="offcanvas"><NavLink activeClassName="active" isActive={() => isActive("/")} to="/contact">Contact</NavLink></li>
                        </ul>
                        <div data-aos={isWideScreen ? "fade-right" : "undefined"} className="footer-social aos-init aos-animate"><ul className="social"><li><a className="facbook" href="https://www.facebook.com/profile.php?id=61560439168465" target="_blank" title="FACEBOOK"><i className="fab fa-facebook-f"></i></a></li><li><a className="instagram" href="https://www.instagram.com/bilvaleaf/" target="_blank" title="INSTAGRAM"><i className="fab fa-instagram"></i></a></li><li><a className="twitter" href="https://twitter.com/bilvaleaf" target="_blank" title="TWITTER">X</a></li>
                            <li><a className="innkedin" href="https://www.linkedin.com/company/bilvaleaf-private-limited/" target="_blank" title="LINKEDIN"><i className="fab fa-linkedin-in"></i></a></li>
                            <li>
                                <a className="innkedin"
                                    href="https://youtube.com/@bilvaleafprivatelimited?si=udxBlecKE6VLzeLV"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="YOUTUBE"
                                    style={{
                                        color: '#FF0000',
                                        textDecoration: 'none',
                                        fontSize: '19px',
                                    }}
                                >
                                    <i className="fab fa-youtube" style={{ fontSize: '24px' }}></i>
                                </a>
                            </li>
                        </ul></div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Header